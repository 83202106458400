<template>
  <v-container style="margin-bottom: 100px">
    <v-row justify="center">
      <v-col cols="12" class="mb-0 pb-0">
        <router-link to="/">
          <v-img
            :src="require('../../assets/evaluator_logo.jpg')"
            contain
            height="50px"
            class="img-class"
          >
          </v-img>
        </router-link>
      </v-col>
      <v-col cols="12" class="pt-0">
        <h3 class="text-center mt-1 mb-5">Cassell Persinger</h3>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'EvaluationList' })"
          large
          class="custom-btn-block"
        >
          Evaluations
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'FollowUp' })"
          large
          class="custom-btn-block"
          >FollowUp
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Bids' })"
          large
          class="custom-btn-block"
          >Bids
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'CustomerSearch' })"
          >Cust Detail
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'TodayProjects' })"
          large
          class="custom-btn-block"
          >Today Projects
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Performance' })"
          large
          class="custom-btn-block"
          >Performance
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'RepTraining' })"
          large
          class="custom-btn-block"
          >Training
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Feedback' })"
          large
          class="custom-btn-block"
          >Feedback
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Settings' })"
          large
          class="custom-btn-block"
          >Settings
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Directory' })"
          large
          class="custom-btn-block"
          >Directory
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="$router.push({ name: 'Drive2Shop' })"
          large
          class="custom-btn-block"
          >Drive2Shop
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="$router.push({ name: 'Home' })"
          >Desktop
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.col-height {
  height: 115px;
}

.custom-btn-block {
  height: 100% !important;
  width: 100% !important;
}
</style>
